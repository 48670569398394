import Swiper from 'swiper';

const benefits = () => {


    $(document).on('click','.benefits__tabs-item',function () {
        const index = $(this).index();
        $('.benefits__tabs-content-item ').removeClass('active');
        $('.benefits__images-item').removeClass('active');
        $('.benefits__tabs-content-item ').eq(index).addClass('active');
        $('.benefits__images-item').eq(index).addClass('active');
        $('.benefits__tabs-item').removeClass('active');
        $(this).addClass('active');
    });

    const sliderWrapper = document.querySelector('.benefits__tabs-content');

    let benefitsSwiper;

    function initSwiper() {
        const screenWidth = $(window).width();
        if(screenWidth < 1200 && benefitsSwiper === undefined) {
            benefitsSwiper = new Swiper('.benefits__tabs-content .swiper-container', {
                loop: true,
                autoplay: {
                    delay: 5000,
                },
                pagination: {
                    el: '.benefits__tabs-content .swiper-pagination',
                    clickable: true,
                },
                on: {
                    realIndexChange(swiper){
                        $('.benefits__images-item').removeClass('active');
                        $('.benefits__images-item').eq(swiper.realIndex).addClass('active');
                    },
                }
            });
        } else if (screenWidth > 1200 && benefitsSwiper !== undefined) {
            benefitsSwiper.destroy();
            benefitsSwiper = undefined;
            jQuery('.benefits__tabs-content .swiper-wrapper').removeAttr('style');
            jQuery('.benefits__tabs-content .swiper-slide').removeAttr('style');
        }
    }
    if (sliderWrapper) {



        initSwiper();

        $(window).on('resize', function(){
            initSwiper();
        });

    }



};
module.exports = benefits;

benefits();
